import { Alert } from "react-bootstrap";
import { useState } from "react";
import classes from "./InlineAddDefinition.module.css";
import LaButton from "../../components/button/LaButton.component";
import { createDefinition } from "../../commons/Api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box } from "@mui/material";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import { getAuthToken } from "../../commons/AuthTokenUtils";
import { useNavigate } from "react-router";
import { ATTR_REDIRECT } from "../../commons/LaConstants";
import { AiOutlinePlus } from "react-icons/ai";
import { COLOR_BNAVY } from "../../commons/LaColors";
import {
  handleErrorRouting,
  preprocessReactQuillContent,
  stripHtml,
  verifyDefinitionContentAndAlert,
} from "../../commons/LaUtils";
import { useEffect } from "react";

const InlineAddDefinition = (props: {
  termId: string;
  termName: string;
  isInline?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [added, setAdded] = useState<boolean>(false); // true
  const [content, setContent] = useState<string>("");
  const [contentEmpty, setContentEmpty] = useState<boolean>(true);

  const navigate = useNavigate();

  const token = getAuthToken();

  const updateContent = (updatedContent: string) => {
    setContent(updatedContent);
    if (stripHtml(updatedContent) && contentEmpty) {
      setContentEmpty(false);
    } else if (!stripHtml(updatedContent) && !contentEmpty) {
      setContentEmpty(true);
    }
  };

  const addDefinition = () => {
    const result = window.confirm(
      "Are you sure you want to submit the definition?"
    );
    if (!result) return;
    if (!verifyDefinitionContentAndAlert(content)) {
      return;
    }
    let preprocessedContent = preprocessReactQuillContent(content);
    setLoading(true);
    createDefinition({
      termId: props.termId,
      termName: props.termName,
      content: preprocessedContent,
    })
      .then((response) => {
        setAdded(true);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => setLoading(false));
  };

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    console.error(event);
    return (event.returnValue = "");
  };

  useEffect(() => {
    if (!contentEmpty) {
      window.addEventListener("beforeunload", handleBeforeUnload, {
        capture: true,
      });
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload, {
        capture: true,
      });
    };
  }, [contentEmpty]);

  return (
    <>
      {loading ? (
        <Box>
          <LaSpinner />
        </Box>
      ) : !added ? (
        <div
          className={`${classes.def_card} ${
            props.isInline ? `${classes.def_card_inline}` : " "
          }`}
        >
          {token ? (
            <>
              <div
                className={
                  classes.inlineDefinitionAreabox +
                  " pb-4 mb-md-0 " +
                  classes.inlineDefinitionTopMargin
                }
              >
                <ReactQuill
                  value={content}
                  style={{ height: "160px" }}
                  placeholder="Add a new definition.."
                  onChange={(value) => {
                    updateContent(value);
                  }}
                  className={`${classes.quillMarginBottom} quill-inline`}
                />
              </div>
              <div className={classes.inlineDefinitionBottomMargin}>
                <LaButton
                  laStyle="btn-yellow"
                  onClick={() => addDefinition()}
                  children={
                    <>
                      <AiOutlinePlus color={COLOR_BNAVY} size={20} /> Add a new
                      definition
                    </>
                  }
                />
              </div>
            </>
          ) : (
            <div>
              {" "}
              <LaButton
                laStyle="btn-yellow"
                onClick={() => {
                  navigate(
                    "/user/login?" + ATTR_REDIRECT + "=/term/" + props.termId
                  );
                }}
                children={
                  <>
                    <AiOutlinePlus color={COLOR_BNAVY} size={20} /> Login to add
                    a new definition
                  </>
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={classes.topMargin + " " + classes.bottomMargin}>
          <Alert>Your definition has been sent successfully!</Alert>
        </div>
      )}
    </>
  );
};

export default InlineAddDefinition;
