import classes from "./misc.module.css";
import searchSvg from "../assets/search-bro.svg";
import arrowSvg from "../assets/arrow.svg";
import LaButton from "../components/button/LaButton.component";
import { AiOutlinePlus } from "react-icons/ai";
import { COLOR_BNAVY } from "../commons/LaColors";
import authorSVg from "../assets/author.svg";
import spaceSvg from "../assets/space.svg";
import evaluateSvg from "../assets/evaluate.svg";
import laptopSvg from "../assets/laptop.svg";
import { useEffect, useState } from "react";
import NewTermForm from "../terms/newTermForm/NewTermForm";
import LaModal from "../components/modal/LaModal.component";
import { ATTR_REDIRECT } from "../commons/LaConstants";
import { useNavigate } from "react-router";
import { getAuthToken } from "../commons/AuthTokenUtils";
import Response_TermsPage from "../types/responses/Response.TermsPage.type";
import { getRecentlyAddedTerms } from "../commons/Api";
import { Box } from "@mui/material";
import LaSpinner from "../components/spinner/LaSpinner.component";

const Home = () => {
  const [show, setShow] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [data, setData] = useState<Response_TermsPage>();
  const [loading, setLoading] = useState(true);

  const handleShow = () => {
    setShow(true);
    setIsSent(false);
  };

  const navigate = useNavigate();

  const handleClose = () => {
    if (!isSent) {
      const result = window.confirm(
        "Are you sure you want to discard the new term changes?"
      );
      if (result) {
        setShow(false);
      }
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setData(undefined);

    getRecentlyAddedTerms(undefined, "5")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    console.error(event);
    return (event.returnValue = "");
  };

  useEffect(() => {
    if (show) {
      window.addEventListener("beforeunload", handleBeforeUnload, {
        capture: true,
      });
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload, {
        capture: true,
      });
    };
  }, [show]);

  const token = getAuthToken();

  return (
    <>
      <div style={{ backgroundColor: "#FAFAFA" }}>
        <div className={classes.hero}>
          <div className="max-width row">
            <div
              className={`col-md-6 px-md-0 d-flex flex-column justify-content-center ${classes.hero_content}`}
            >
              <h3>
                “If you can't explain it simply, <br /> you don't understand it
                well enough”
              </h3>
              <h4>- Albert Einstein</h4>
              <div className="d-flex flex-column ">
                <h5>Your Knowledge Counts!</h5>
                <div
                  className={`d-flex align-items-end ${classes.justifyContentCenter}`}
                >
                  <LaButton
                    onClick={() => {
                      if (!token) {
                        navigate("/user/login?" + ATTR_REDIRECT + "=/");
                      }
                      handleShow();
                    }}
                    children={
                      <>
                        <AiOutlinePlus color={COLOR_BNAVY} size={20} /> Add a
                        new term
                      </>
                    }
                    laStyle="btn-yellow"
                    customClassName={`${classes.btn} mt-md-5 mt-4`}
                  />
                  <div
                    className={`${classes.illustration} my-auto ms-md-4 ms-4 w-max ${classes.display_none}`}
                  >
                    <img src={arrowSvg} className="px-md-0" alt={"arrowSvg"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-col justify-content-center pl-0 h-full pt-5 pt-md-0">
              <div
                className={`${classes.illustration} my-auto ms-auto me-auto me-md-0 `}
              >
                <img
                  src={searchSvg}
                  className="px-0 mx-auto img-fluid"
                  alt={"searchSvg"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`max-width ${classes.home_content}`}>
          <h3>
            There is still hidden knowledge in our brains that we need to easily
            express <br /> AI tools are intelligent enough to compile our
            knowledge, yet we need to feed such tools with a clean input
          </h3>
          <h1>Lapedia is an encyclopedia in a new style, offering</h1>
          <div className="row gap-3 gap-md-0 col-12 mx-auto px-0">
            <div className="col-md-4 d-flex flex-column justify-content-center">
              <div className="d-flex shadow justify-content-start align-items-center rounded gap-3 p-4">
                <div>
                  <img src={authorSVg} className="px-md-0" alt={"authorSVg"} />
                </div>
                <h4>Everyone can author</h4>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column justify-content-center">
              <div className="d-flex shadow justify-content-start align-items-center rounded gap-3 p-4">
                <div>
                  <img src={spaceSvg} className="px-md-0" alt={"spaceSvg"} />
                </div>
                <h4>Everyone owns their space</h4>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column justify-content-center">
              <div className="d-flex shadow justify-content-start align-items-center rounded gap-3 p-4">
                <div>
                  <img
                    src={evaluateSvg}
                    className="px-md-0"
                    alt={"evaluateSvg"}
                  />
                </div>
                <h4>You write, others evaluate</h4>
              </div>
            </div>
          </div>
        </div>
        <div className={`${classes.home_terms} bg-white`}>
          <div className="max-width row col-12">
            <div className="col-md-6 px-0">
              <h1>Recently Added Terms</h1>
              {loading ? (
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <LaSpinner />
                </Box>
              ) : (
                <ul className="list-group">
                  {data &&
                    data?.terms.map((item, index) => {
                      return <li key={index}>{item.name}</li>;
                    })}
                  <li key="...">...</li>
                </ul>
              )}
              <LaButton
                onClick={() => {
                  navigate("/recentlyAdded");
                }}
                children={<>Learn more</>}
                laStyle="btn-yellow"
                customClassName={`${classes.btn} mt-md-5 mt-3 mb-3`}
              />
            </div>
            <div className="col-md-6 d-flex flex-column justify-items-center align-items-center pt-md-0 pt-5">
              <div
                className={`${classes.illustration} my-auto ms-auto me-auto me-md-0 `}
              >
                <img
                  src={laptopSvg}
                  className="px-md-0 px-2 mx-auto img-fluid"
                  alt={"laptopSvg"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LaModal
        show={show}
        onHide={handleClose}
        body={<NewTermForm setIsSent={setIsSent} />}
        backdrop={true}
      />
    </>
  );
};
export default Home;
