import { AxiosError } from "axios";
import State_Type from "../types/State.type";
import { EMAIL_REGEX, HTML_REGEX } from "./LaConstants";
import { logout } from "./AuthTokenUtils";

export const epochToDateTime = (createdOn: number) => {
  return new Date(createdOn).toLocaleTimeString("en-US", {
    //weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const epochToOnlyDate = (createdOn: number) => {
  return new Date(createdOn).toLocaleDateString("en-US", {
    //weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const randomString = (length: number) => {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const toPrettyTermName = (termName: string) => {
  return termName.trim().replace(/\s+/g, "-").toLowerCase();
};

export const stateToString = (e: State_Type) => {
  return e + "";
};

export const stripHtml = (st: string): string => {
  if (!st) {
    return st;
  }
  return st.replace(HTML_REGEX, "");
};

export const preprocessReactQuillContent = (content: string): string => {
  if (!content) {
    return content;
  }
  return content.replace(/<p><br><\/p>/g, "");
};

export const isValidEmail = (email: string | undefined): boolean => {
  if (email && typeof email === "string" && email.match(EMAIL_REGEX)) {
    return true;
  } else {
    return false;
  }
};

export const verifyDefinitionContentAndAlert = (content: string): boolean => {
  if (!content) {
    alert("Invalid content.");
    return false;
  }
  if (stripHtml(content).length < 15) {
    alert("Your definition should contain at least 15 characters.");
    return false;
  }
  if (stripHtml(content).length > 30000) {
    alert("Your definition may contain at most 30,000 characters.");
    return false;
  }
  return true;
};

export const verifyTermNameAndAlert = (name: string): boolean => {
  if (!name) {
    alert("Invalid term name.");
    return false;
  }
  if (name.length < 2) {
    alert("Your term name should contain at least 2 characters.");
    return false;
  }
  if (name.length > 150) {
    alert("Your term name may contain at most 150 characters.");
    return false;
  }
  return true;
};

// Password length should be 6-20
export const isValidPassword = (password: string): boolean => {
  if (password && password.length >= 6 && password.length <= 20) {
    return true;
  } else {
    return false;
  }
};

// Display name length should be 2-70
export const isValidDisplayName = (displayName: string): boolean => {
  if (displayName && displayName.length >= 2 && displayName.length <= 70) {
    return true;
  } else {
    return false;
  }
};

// First and last names should be either empty or their length is between 3-35
export const isValidFirstOrLastName = (name: string): boolean => {
  if (!name || (name && name.length >= 3 && name.length <= 35)) {
    return true;
  } else {
    return false;
  }
};

// 'About' length should be at most 1500 chars
export const isValidAbout = (about: string): boolean => {
  if (!about || (about && about.length <= 1500)) {
    return true;
  } else {
    return false;
  }
};

/**
 *
 * -1 indicates network error
 *
 * @param error
 * @returns
 */
export const analyzeAxiosError = (error: AxiosError): number => {
  if (!error.response) {
    return -1;
  }

  return error.response.status;
};

export const getErrorRoute = (
  error: AxiosError,
  redirect403?: string
): string => {
  let errorCode = analyzeAxiosError(error);

  if (errorCode === -1 || errorCode >= 500) {
    return "/maintenance";
  } else if (errorCode === 404) {
    return "/notfound";
  } else if (errorCode === 403 || errorCode === 401) {
    logout();
    if (redirect403) {
      return redirect403;
    } else {
      return "/notice/Please log in!";
    }
  } else {
    return "/nomatch";
  }
};

export const handleErrorRouting = (
  error: AxiosError,
  navigate: (url: string) => void,
  redirect403?: string
): void => {
  let errorCode = analyzeAxiosError(error);

  let adoptedUrl = "/";
  if (errorCode === -1 || errorCode >= 500) {
    adoptedUrl = "/maintenance";
  } else if (errorCode === 404) {
    adoptedUrl = "/notfound";
  } else if (errorCode === 403 || errorCode === 401) {
    if (redirect403) {
      adoptedUrl = redirect403;
    } else {
      adoptedUrl = "/notice/Please log in!";
    }
  } else {
    adoptedUrl = "/nomatch";
  }
  logout(() => navigate(adoptedUrl));
};

export const getInitials = (name: any): string => {
  return name.match(/(\b\S)?/g).join("");
};

/*
export const getErrorElement = (error: AxiosError): any => {
  let errorCode = analyzeAxiosError(error);

  if (errorCode === -1 || errorCode >= 500) {
    return <NoMatch type={NoMatch_Type.maintenance} />;
  } else if (errorCode === 404) {
    return <NoMatch type={NoMatch_Type.notFound} />;
  } else if (errorCode === 403 || errorCode === 401) {
    logout();
    return <NoMatch type={NoMatch_Type.forbidden} />;
  } else {
    return <NoMatch type={NoMatch_Type.exception} />;
  }
};
*/
