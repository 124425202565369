import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteObject, useRoutes } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./misc/Home";
import About from "./misc/About";
import NoMatch, { NoMatch_Type } from "./misc/NoMatch";
import TermTree from "./terms/termTree/TermTree";
import DefinitionCard from "./definitions/definitionCard/DefinitionCard";
import LogIn from "./user/logIn/LogIn";
import SignUp from "./user/signUp/SignUp";
import TermCardList from "./terms/termCardList/TermCardList";
import Preferences from "./user/preferences/Preferences";
import DefinitionCardList, {
  DefinitionCardList_Type,
} from "./definitions/definitionCardList/DefinitionCardList";
import UserProfile from "./user/preferences/userProfile/UserProfile";
import AdminSettings from "./user/adminSettings/AdminSettings";
import PendingTermCardList from "./terms/pendingTermCardList/PendingTermCardList";
import DisplayById, {
  DisplayById_Type,
} from "./user/adminSettings/displayById/DisplayById";
import ChangePassword from "./user/preferences/changePassword/ChangePassword";
import AllTables from "./user/adminSettings/allTables/AllTables";
import ChangeEmail from "./user/preferences/changeEmail/ChangeEmail";
import Activation from "./user/activation/Activation";
import ForgotPassword from "./user/forgotPassword/ForgotPassword";
import ResetPassword from "./user/resetPassword/ResetPassword";
import Notice from "./misc/Notice";
import SearchResultsPage from "./search/searchResultsPage/SearchResultsPage";
import NotificationCardList, {
  NotificationCardList_Type,
} from "./notification/notificationCardList/NotificationCardList";
import { AuthorProtectedRoute } from "./routing/AuthorProtectedRoute";
import { AdminProtectedRoute } from "./routing/AdminProtectedRoute";
import { AlreadyLoggedInRoute } from "./routing/AlreadyLoggedInRoute";
import DeleteAccount from "./user/preferences/deleteAccount/DeleteAccount";
import TermsAndConditions from "./misc/TermsAndConditions";
import PrivacyPolicy from "./misc/PrivacyPolicy";
import LegalNotice from "./misc/LegalNotice";
import PersonalData from "./user/preferences/downloadPersonalData/PersonalData";
import UserProfilePage from "./user/profilePage/UserProfilePage";
import AllUsers from "./user/adminSettings/allUsers/AllUsers";

function App() {
  let routes: RouteObject[] = [
    {
      path: "/",
      element: <Layout />,
      errorElement: <NoMatch type={NoMatch_Type.exception} />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "termsandconditions",
          element: <TermsAndConditions />,
        },
        {
          path: "privacypolicy",
          element: <PrivacyPolicy />,
        },
        {
          path: "legalnotice",
          element: <LegalNotice />,
        },
        {
          path: "/*",
          element: <NoMatch type={NoMatch_Type.notFound} />,
        },
        {
          path: "/forbidden",
          element: <NoMatch type={NoMatch_Type.forbidden} />,
        },
        {
          path: "/maintenance",
          element: <NoMatch type={NoMatch_Type.maintenance} />,
        },
        {
          path: "recentlyadded",
          element: <TermCardList />,
        },
        {
          path: "search/:query",
          element: <SearchResultsPage />,
        },
        {
          path: "notice/:notice",
          element: <Notice />,
        },
        {
          path: "term/:id",
          element: <TermTree />,
        },
        {
          path: "term/:id/:name",
          element: <TermTree />,
        },
        {
          path: "definition/:id",
          element: <DefinitionCard includeInlineAddDefinition={true} />,
        },
        {
          path: "la/:userId",
          element: <UserProfilePage />,
        },
        {
          path: "user",
          children: [
            {
              path: "login",
              element: (
                <AlreadyLoggedInRoute>
                  <LogIn />
                </AlreadyLoggedInRoute>
              ),
            },
            {
              path: "signup",
              element: (
                <AlreadyLoggedInRoute>
                  <SignUp />
                </AlreadyLoggedInRoute>
              ),
            },
            {
              path: "notifications",
              element: (
                <AuthorProtectedRoute>
                  <NotificationCardList
                    type={NotificationCardList_Type.authorSelfNotifications}
                  />
                </AuthorProtectedRoute>
              ),
            },
            {
              path: "activate/:token",
              element: <Activation />,
            },
            {
              path: "forgotpassword",
              element: <ForgotPassword />,
            },
            {
              path: "resetpassword/:token",
              element: <ResetPassword />,
            },
            {
              path: "preferences",
              element: (
                <AuthorProtectedRoute>
                  <Preferences />
                </AuthorProtectedRoute>
              ),
              children: [
                {
                  path: "profile",
                  element: (
                    <AuthorProtectedRoute>
                      <UserProfile />
                    </AuthorProtectedRoute>
                  ),
                },
                {
                  path: "changeemail",
                  element: (
                    <AuthorProtectedRoute>
                      <ChangeEmail />
                    </AuthorProtectedRoute>
                  ),
                },
                {
                  path: "changepassword",
                  element: (
                    <AuthorProtectedRoute>
                      <ChangePassword />
                    </AuthorProtectedRoute>
                  ),
                },
                {
                  path: "deleteaccount",
                  element: (
                    <AuthorProtectedRoute>
                      <DeleteAccount />
                    </AuthorProtectedRoute>
                  ),
                },
                {
                  path: "definitions",
                  element: (
                    <AuthorProtectedRoute>
                      <DefinitionCardList
                        type={DefinitionCardList_Type.myDefinitions}
                      />
                    </AuthorProtectedRoute>
                  ),
                },
                {
                  path: "personaldata",
                  element: (
                    <AuthorProtectedRoute>
                      <PersonalData />
                    </AuthorProtectedRoute>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "admin",
          children: [
            {
              path: "settings",
              element: (
                <AdminProtectedRoute>
                  <AdminSettings />
                </AdminProtectedRoute>
              ),
              children: [
                {
                  path: "termcard",
                  element: (
                    <AdminProtectedRoute>
                      <DisplayById
                        type={DisplayById_Type.term}
                        key="admin-settings-termcard"
                      />
                    </AdminProtectedRoute>
                  ),
                },
                {
                  path: "term",
                  element: (
                    <AdminProtectedRoute>
                      <DisplayById
                        type={DisplayById_Type.termTree}
                        key="admin-settings-term"
                      />
                    </AdminProtectedRoute>
                  ),
                },
                {
                  path: "definition",
                  element: (
                    <AdminProtectedRoute>
                      <DisplayById
                        type={DisplayById_Type.definition}
                        key="admin-settings-definition"
                      />
                    </AdminProtectedRoute>
                  ),
                },
                {
                  path: "pending",
                  element: (
                    <AdminProtectedRoute>
                      <PendingTermCardList key="admin-settings-pendingterms" />
                    </AdminProtectedRoute>
                  ),
                },
                {
                  path: "authordefinitions",
                  element: (
                    <AdminProtectedRoute>
                      <DisplayById
                        type={DisplayById_Type.authorDefinitions}
                        key="admin-settings-authordefinitions"
                      />
                    </AdminProtectedRoute>
                  ),
                },
                {
                  path: "authorprofile",
                  element: (
                    <AdminProtectedRoute>
                      <DisplayById
                        type={DisplayById_Type.authorProfile}
                        key="admin-settings-authorprofile"
                      />
                    </AdminProtectedRoute>
                  ),
                },
                {
                  path: "alltables",
                  element: (
                    <AdminProtectedRoute>
                      <AllTables />
                    </AdminProtectedRoute>
                  ),
                },                {
                  path: "allusers",
                  element: (
                    <AdminProtectedRoute>
                      <AllUsers />
                    </AdminProtectedRoute>
                  ),
                },
                {
                  path: "adminnotifications",
                  element: (
                    <AdminProtectedRoute>
                      {" "}
                      <NotificationCardList
                        type={NotificationCardList_Type.adminNotifications}
                      />
                    </AdminProtectedRoute>
                  ),
                },
                {
                  path: "authornotifications",
                  element: (
                    <AdminProtectedRoute>
                      <DisplayById
                        type={DisplayById_Type.authorNotifications}
                      />
                    </AdminProtectedRoute>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  // The useRoutes() hook allows you to define your routes as JavaScript objects
  // instead of <Routes> and <Route> elements. This is really just a style
  // preference for those who prefer to not use JSX for their routes config.
  let element = useRoutes(routes);

  return <>{element}</>;
}

export default App;
