import RequestBody_CreateDefinition from "../types/requestBodies/RequestBody.CreateDefinition.type";
import RequestBody_UpdateDefinition from "../types/requestBodies/RequestBody.UpdateDefinition.type";
import Response_Definition from "../types/responses/Response.Definition.type";
import { AxiosError } from "axios";
import {
  URL_USER_DEFINITION,
  URL_USER_DEFINITIONS,
  URL_ADMIN_AUTHOR_DEFINITIONS,
  URL_ADMIN_PENDING_TERMS,
  URL_ADMIN_ALL_TABLES,
  URL_USER_PROFILE_CHANGE_PASSWORD,
  URL_AUTH_AUTHENTICATE,
  URL_TOTAL_DEFINITION_COUNT,
  URL_TERMTREE,
  URL_SEARCH,
  URL_RECENTLY_ADDED_TERMS,
  URL_USER_DEFINITION_CREATE,
  URL_DEFINITION_ID,
  URL_USER_DEFINITION_ID,
  ACTION_ADD_UPVOTE,
  ACTION_ADD_DOWNVOTE,
  ACTION_DELETE_UPVOTE,
  ACTION_DELETE_DOWNVOTE,
  URL_AUTH_REGISTER,
  URL_ADMIN_TERM_ID,
  URL_ADMIN_TERM_APPROVE,
  URL_ADMIN_DEFINITION_ID,
  URL_ADMIN_TERMTREE,
  URL_USER_PROFILE_CHANGE_EMAIL,
  URL_USER_NOTIFICATIONS_MARKASSEEN,
  URL_ADMIN_USERNOTIFICATIONS,
  URL_ADMIN_NOTIFICATIONS,
  URL_ADMIN_NOTIFICATIONS_MARKASSEEN,
  URL_USER_PROFILE,
  URL_USER_NOTIFICATIONS,
  URL_AUTH_ACTIVATE,
  URL_AUTH_FORGOT_PASSWORD,
  URL_AUTH_RESET_PASSWORD,
  URL_SEARCH_TERMS,
  URL_SEARCH_DEFINITIONS,
  URL_ADMIN_USERPROFILE,
  URL_SEARCH_DEFINITIONS_FULL,
  URL_AUTH_VERIFYRESETPASSWORDTOKEN,
  URL_USER_PROFILE_PUBLIC,
  URL_AUTHOR_DEFINITIONS_PUBLIC,
  URL_ADMIN_ALL_USERS,
} from "../commons/LaConstants";
import { AxiosResponse } from "axios";
import Response_TermsPage from "../types/responses/Response.TermsPage.type";
import { Response_TermTree } from "../types/responses/Response.TermTree.type";
import Response_SearchResult from "../types/responses/Response.SearchResult.type";
import Response_Jwt from "../types/responses/Response.Jwt.type";
import Response_DefinitionsPage from "../types/responses/Response.DefinitionsPage.type";
import Response_UserProfile from "../types/responses/Response.UserProfile.type";
import { laAxios } from "./laAxios";
import Response_PendingTermsPage from "../types/responses/Response.PendingTerm.type";
import RequestBody_ChangeEmail from "../types/requestBodies/RequestBody.ChangeEmail.type";
import RequestBody_ChangePassword from "../types/requestBodies/RequestBody.ChangePassword.type";
import RequestBody_RegisterUser from "../types/requestBodies/RequestBody.RegisterUser.type";
import RequestBody_AuthenticateUser from "../types/requestBodies/RequestBody.AuthenticateUser.type";
import Response_Term from "../types/responses/Response.Term.type";
import RequestBody_UpdateTermName from "../types/requestBodies/RequestBody.UpdateTermName.type";
import RequestBody_ApproveTermName from "../types/requestBodies/RequestBody.ApproveTermName.type";
import RequestBody_UpdateUser from "../types/requestBodies/RequestBody.UpdateUser.type";
import RequestBody_MarkAsSeen from "../types/requestBodies/RequestBody.MarkAsSeen.type";
import Response_Notification from "../types/responses/Response.Notification.type";
import RequestBody_ResetPassword from "../types/requestBodies/RequestBody.ResetPassword.type";
import { getAuthToken } from "./AuthTokenUtils";
import Response_DefinitionsSearchResultPage from "../types/responses/Response.DefinitionsSearchResultPage.type";
import Response_RegisterUser from "../types/responses/Response.RegisterUser";
import Response_ChangeEmail from "../types/responses/Response.ChangeEmail";
import Response_UserProfilePublic from "../types/responses/Response.UserProfilePublic.type";

export const getDefinition = (
  id: string
): Promise<AxiosResponse<Response_Definition, any>> => {
  var url = URL_DEFINITION_ID + id;
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_Definition>(url)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getRecentlyAddedTerms = (
  pagingState?: string,
  pageSize?: string
): Promise<AxiosResponse<Response_TermsPage, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_TermsPage>(URL_RECENTLY_ADDED_TERMS, {
        params: { pagingState: pagingState, pageSize: pageSize },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getTotalDefinitionCount = (
  id: string
): Promise<AxiosResponse<number, any>> => {
  return new Promise((resolve, reject) => {
    var url = URL_TOTAL_DEFINITION_COUNT + id;
    laAxios
      .get<number>(url)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getTermTree = (
  id: string,
  orderedBy?: string,
  pagingState?: string,
  pageSize?: number
): Promise<AxiosResponse<Response_TermTree, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_TermTree>(URL_TERMTREE, {
        params: {
          id: id,
          orderedBy: orderedBy,
          pagingState: pagingState,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const searchAsYouType = (
  query: string,
  size: number
): Promise<AxiosResponse<Response_SearchResult, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_SearchResult>(URL_SEARCH, {
        params: {
          query: query,
          size: size,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const searchTerms = (
  query: string,
  pagingState: number,
  pageSize: number
): Promise<AxiosResponse<Response_TermsPage, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_TermsPage>(URL_SEARCH_TERMS, {
        params: {
          query: query,
          pagingState: pagingState,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const searchDefinitions = (
  query: string,
  pagingState: number,
  pageSize: number
): Promise<AxiosResponse<Response_DefinitionsSearchResultPage, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_DefinitionsSearchResultPage>(URL_SEARCH_DEFINITIONS, {
        params: {
          query: query,
          pagingState: pagingState,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const searchDefinitionsFull = (
  query: string,
  pagingState: number,
  pageSize: number
): Promise<AxiosResponse<Response_DefinitionsPage, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_DefinitionsPage>(URL_SEARCH_DEFINITIONS_FULL, {
        params: {
          query: query,
          pagingState: pagingState,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

/**
 * User
 */
export const getUserProfile = (): Promise<
  AxiosResponse<Response_UserProfile, any>
> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_UserProfile>(URL_USER_PROFILE)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getUserProfilePublic = (
  id: string
): Promise<AxiosResponse<Response_UserProfilePublic, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_UserProfilePublic>(URL_USER_PROFILE_PUBLIC, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getAuthorDefinitionsPublic = (
  id: string,
  orderedBy?: string,
  pagingState?: string,
  pageSize?: number
): Promise<AxiosResponse<Response_DefinitionsPage, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_DefinitionsPage>(URL_AUTHOR_DEFINITIONS_PUBLIC, {
        params: {
          id: id,
          orderedBy: orderedBy,
          pagingState: pagingState,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getUserProfile_Admin = (
  id: string
): Promise<AxiosResponse<Response_UserProfile, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_UserProfile>(URL_ADMIN_USERPROFILE, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const updateUserProfile = (
  body: RequestBody_UpdateUser
): Promise<AxiosResponse<Response_UserProfile, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<Response_UserProfile>(URL_USER_PROFILE, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const updateUserProfile_Admin = (
  id: string,
  body: RequestBody_UpdateUser
): Promise<AxiosResponse<Response_UserProfile, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<Response_UserProfile>(URL_ADMIN_USERPROFILE, body, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const changePassword = (
  body: RequestBody_ChangePassword
): Promise<AxiosResponse<boolean, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<boolean>(URL_USER_PROFILE_CHANGE_PASSWORD, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const changeEmail = (
  body: RequestBody_ChangeEmail
): Promise<AxiosResponse<Response_ChangeEmail, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<Response_ChangeEmail>(URL_USER_PROFILE_CHANGE_EMAIL, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const deleteUserAccount = (
  password: string
): Promise<AxiosResponse<boolean, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .delete<boolean>(URL_USER_PROFILE, {
        params: {
          password: password,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const deleteUserAccount_Admin = (
  id: string
): Promise<AxiosResponse<boolean, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .delete<boolean>(URL_ADMIN_USERPROFILE, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const createDefinition = (
  body: RequestBody_CreateDefinition
): Promise<AxiosResponse<Response_Definition, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<Response_Definition>(URL_USER_DEFINITION_CREATE, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const updateDefinition = (
  id: string,
  body: RequestBody_UpdateDefinition
): Promise<AxiosResponse<Response_Definition, any>> => {
  return new Promise((resolve, reject) => {
    var url = URL_USER_DEFINITION_ID + id;
    laAxios
      .post<Response_Definition>(url, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const deleteDefinition = (
  id: string
): Promise<AxiosResponse<Response_Definition, any>> => {
  return new Promise((resolve, reject) => {
    var url = URL_USER_DEFINITION_ID + id;
    laAxios
      .delete<Response_Definition>(url)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getUserDefinitions = (
  orderedBy?: string,
  pagingState?: string,
  pageSize?: number
): Promise<AxiosResponse<Response_DefinitionsPage, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_DefinitionsPage>(URL_USER_DEFINITIONS, {
        params: {
          orderedBy: orderedBy,
          pagingState: pagingState,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const updateVote = (
  definitionId: string,
  add: boolean,
  up: boolean
): Promise<AxiosResponse<Response_Definition, any>> => {
  return new Promise((resolve, reject) => {
    let action;
    if (add && up) {
      action = ACTION_ADD_UPVOTE;
    }
    if (add && !up) {
      action = ACTION_ADD_DOWNVOTE;
    }
    if (!add && up) {
      action = ACTION_DELETE_UPVOTE;
    }
    if (!add && !up) {
      action = ACTION_DELETE_DOWNVOTE;
    }
    var url = URL_USER_DEFINITION + action;

    laAxios
      .put<Response_Definition>(url, null, {
        params: {
          definitionId: definitionId,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

/**
 * Admin
 */
export const getTerm_Admin = (
  termId: string
): Promise<AxiosResponse<Response_Term, any>> => {
  return new Promise((resolve, reject) => {
    let url = URL_ADMIN_TERM_ID + termId;
    laAxios
      .get<Response_Term>(url)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

/** TODO-adi */
export const updateTerm_Admin = (
  termId: string,
  body: RequestBody_UpdateTermName
): Promise<AxiosResponse<Response_TermTree, any>> => {
  return new Promise((resolve, reject) => {
    let url = URL_ADMIN_TERM_ID + termId;
    laAxios
      .post<Response_TermTree>(url, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const approveTerm_Admin = (
  body: RequestBody_ApproveTermName
): Promise<AxiosResponse<Response_TermTree, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<Response_TermTree>(URL_ADMIN_TERM_APPROVE, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getDefinition_Admin = (
  id: string
): Promise<AxiosResponse<Response_Definition, any>> => {
  return new Promise((resolve, reject) => {
    var url = URL_ADMIN_DEFINITION_ID + id;
    laAxios
      .get<Response_Definition>(url)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const updateDefinition_Admin = (
  definitionId: string,
  body: RequestBody_UpdateDefinition
): Promise<AxiosResponse<Response_Definition, any>> => {
  return new Promise((resolve, reject) => {
    let url = URL_ADMIN_DEFINITION_ID + definitionId;
    laAxios
      .post<Response_Definition>(url, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const deleteDefinition_Admin = (
  definitionId: string
): Promise<AxiosResponse<Response_Definition, any>> => {
  return new Promise((resolve, reject) => {
    let url = URL_ADMIN_DEFINITION_ID + definitionId;
    laAxios
      .delete<Response_Definition>(url)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getTermTree_Admin = (
  termId: string,
  orderedBy?: string,
  pagingState?: string,
  pageSize?: number
): Promise<AxiosResponse<Response_TermTree, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_TermTree>(URL_ADMIN_TERMTREE, {
        params: {
          id: termId,
          orderedBy: orderedBy,
          pagingState: pagingState,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

/** TODO-adi */
export const deleteTermTree_Admin = (
  id: string
): Promise<AxiosResponse<Response_Term, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .delete<Response_Term>(URL_ADMIN_TERMTREE, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getPendingTerms_Admin = (
  pagingState?: string,
  pageSize?: number
): Promise<AxiosResponse<Response_PendingTermsPage, any>> => {
  return new Promise((resolve, reject) => {
    const token = getAuthToken();
    if (!token) {
      return Promise.reject("Token undefined");
    }
    laAxios
      .get<Response_PendingTermsPage>(URL_ADMIN_PENDING_TERMS, {
        params: {
          pagingState: pagingState,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getAuthorDefinitions_Admin = (
  id: string,
  orderedBy?: string,
  pagingState?: string,
  pageSize?: number
): Promise<AxiosResponse<Response_DefinitionsPage, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_DefinitionsPage>(URL_ADMIN_AUTHOR_DEFINITIONS, {
        params: {
          id: id,
          orderedBy: orderedBy,
          pagingState: pagingState,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getAllTables_Admin = (): Promise<AxiosResponse<string, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<string>(URL_ADMIN_ALL_TABLES)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getAllUsers_Admin = (): Promise<AxiosResponse<string, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<string>(URL_ADMIN_ALL_USERS)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

/**
 * Auth
 */
export const authenticate = (
  body: RequestBody_AuthenticateUser
): Promise<AxiosResponse<Response_Jwt, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<Response_Jwt>(URL_AUTH_AUTHENTICATE, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const register = (
  body: RequestBody_RegisterUser
): Promise<AxiosResponse<Response_RegisterUser, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<Response_RegisterUser>(URL_AUTH_REGISTER, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const activate = (
  token: string
): Promise<AxiosResponse<String, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<String>(URL_AUTH_ACTIVATE, {
        params: {
          token: token,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const forgotPassword = (
  email: string
): Promise<AxiosResponse<boolean, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .put<boolean>(URL_AUTH_FORGOT_PASSWORD, null, {
        params: {
          email: email,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const resetPassword = (
  body: RequestBody_ResetPassword
): Promise<AxiosResponse<String, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<String>(URL_AUTH_RESET_PASSWORD, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const verifyResetPasswordToken = (
  token: string | undefined
): Promise<AxiosResponse<Boolean, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Boolean>(URL_AUTH_VERIFYRESETPASSWORDTOKEN, {
        params: {
          token: token,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

/**
 * Notification (User and Admin)
 */
export const markUserNotificationsAsSeen = (
  body: RequestBody_MarkAsSeen
): Promise<AxiosResponse<number, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<number>(URL_USER_NOTIFICATIONS_MARKASSEEN, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

/** TODO-adi */
export const markAdminNotificationsAsSeen = (
  body: RequestBody_MarkAsSeen
): Promise<AxiosResponse<number, any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .post<number>(URL_ADMIN_NOTIFICATIONS_MARKASSEEN, body)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getUserUnreadNotifications = (): Promise<
  AxiosResponse<Response_Notification[], any>
> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_Notification[]>(URL_USER_NOTIFICATIONS)
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getAllUserNotifications_Admin = (
  id: string
): Promise<AxiosResponse<Response_Notification[], any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_Notification[]>(URL_ADMIN_USERNOTIFICATIONS, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};

export const getAdminNotifications_Admin = (
  includeSeenNotifications: boolean
): Promise<AxiosResponse<Response_Notification[], any>> => {
  return new Promise((resolve, reject) => {
    laAxios
      .get<Response_Notification[]>(URL_ADMIN_NOTIFICATIONS, {
        params: {
          includeSeenNotifications: includeSeenNotifications,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error: AxiosError) {
        reject(error);
      });
  });
};
