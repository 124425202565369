import { Container } from "react-bootstrap";
import { getAllUsers_Admin } from "../../../commons/Api";
import { useState } from "react";
import { Box } from "@mui/material";
import LaSpinner from "../../../components/spinner/LaSpinner.component";
import { handleErrorRouting } from "../../../commons/LaUtils";
import { useNavigate } from "react-router";

const AllUsers = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<string>("");

  const navigate = useNavigate();

  getAllUsers_Admin()
    .then((response) => {
      setUsers(response.data);
    })
    .catch((error) => {
      handleErrorRouting(error, (url: string) => navigate(url));
    })
    .finally(() => {
      setLoading(false);
    });

  return loading ? (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <LaSpinner />
    </Box>
  ) : (
    <Container>
      <div>
        {users.split("\n").map((i, key) => {
          return (
            <>
              <div key={key}>
                {i ? i : ""}
              </div>
              <div key={key}>
                {"--------------------------------------------"}
              </div></>
          );
        })}
      </div>
    </Container>
  );
};
export default AllUsers;
