import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import classes from "./ChangeEmail.module.css";
import PageTitle from "../../../components/pageTitle/PageTitle.component";
import { changeEmail, getUserProfile } from "../../../commons/Api";
import LaButton from "../../../components/button/LaButton.component";
import { Alert } from "react-bootstrap";
import StyledTextField from "../../../components/mui/StyledTextField";
import LaSpinner from "../../../components/spinner/LaSpinner.component";
import {
  analyzeAxiosError,
  handleErrorRouting,
  isValidEmail,
  isValidPassword,
} from "../../../commons/LaUtils";
import { COLOR_BMEDIUMGRAY } from "../../../commons/LaColors";
import { BiSolidEnvelope, BiSolidLock } from "react-icons/bi";
import { BsCaretLeftFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import InlineErrorMessage from "../../../components/alerts/InlineErrorMessage.component";
import { Typography } from "@mui/material";

const ChangeEmail = () => {
  const [loading, setLoading] = useState(true);
  const [oldEmail, setOldEmail] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [unsuccessfulMessage, setUnsuccessfulMessage] = useState<
    string | undefined
  >(undefined);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );

  const navigate = useNavigate();

  let WRONG_CREDENTIALS_MESSAGE = "Invalid email or password";

  useEffect(() => {
    setUnsuccessfulMessage(undefined);
    setLoading(true);
    getUserProfile()
      .then((response) => {
        setOldEmail(response.data.email);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = () => {
    setUnsuccessfulMessage(undefined);
    if (!isValidEmail(newEmail)) {
      setUnsuccessfulMessage(WRONG_CREDENTIALS_MESSAGE);
      return;
    }

    if (
      !password ||
      typeof password !== "string" ||
      !isValidPassword(password)
    ) {
      setUnsuccessfulMessage(WRONG_CREDENTIALS_MESSAGE);
      return;
    }

    setUnsuccessfulMessage(undefined);
    setLoading(true);
    changeEmail({
      password: password,
      email: newEmail,
    })
      .then((response) => {
        if (response.data.httpStatus >= 200 && response.data.httpStatus < 300) {
          setAlertMessage(response.data.message);
        } else {
          setUnsuccessfulMessage(response.data.message);
        }
      })
      .catch((error) => {
        let errorCode = analyzeAxiosError(error);
        if (errorCode === -1 || errorCode >= 500) {
          handleErrorRouting(error, (url: string) => navigate(url));
        } else {
          setUnsuccessfulMessage(WRONG_CREDENTIALS_MESSAGE);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.emailPage}>
      <>
        <PageTitle
          title="Change email"
          insidePage
          backButton={
            <BsCaretLeftFill
              onClick={() => navigate("/user/preferences")}
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          }
        />
        {loading ? (
          <LaSpinner />
        ) : alertMessage ? (
          <div>
            <Alert>{alertMessage}</Alert>
          </div>
        ) : (
          <Box sx={{ mt: 1, width: { xs: "100%", md: "50%" } }}>
            {unsuccessfulMessage && (
              <InlineErrorMessage errorMessage={[unsuccessfulMessage]} />
            )}
            <Typography
              align="left"
              component="h1"
              variant="body1"
              sx={{ mb: 2 }}
            >
              <span className={classes.userSpan}>Current email : </span> <br />{" "}
              {oldEmail}
            </Typography>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={
                <>
                  <BiSolidEnvelope
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  New email address
                </>
              }
              name="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              size="small"
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="password"
              label={
                <>
                  <BiSolidLock
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  Password
                </>
              }
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size="small"
            />
            <div className={classes.buttonTopMargin}>
              <LaButton
                type="submit"
                onClick={() => handleSubmit()}
                laStyle="btn-navy"
              >
                Change email
              </LaButton>
            </div>
          </Box>
        )}
      </>
    </div>
  );
};

export default ChangeEmail;
