import React from "react";
import { Alert, Form } from "react-bootstrap";
import VotingGrid from "../../components/votingGrid/VotingGrid.component";
import LaButton from "../../components/button/LaButton.component";
import Response_Definition from "../../types/responses/Response.Definition.type";
import classes from "./DefinitionInTermTree.module.css";
import LaModal from "../../components/modal/LaModal.component";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  deleteDefinition,
  deleteDefinition_Admin,
  updateDefinition,
  updateDefinition_Admin,
} from "../../commons/Api";
import {
  epochToDateTime,
  getInitials,
  handleErrorRouting,
  preprocessReactQuillContent,
  verifyDefinitionContentAndAlert,
} from "../../commons/LaUtils";
import {
  fetchUserIdFromJwt,
  fetchRoleFromJwt,
} from "../../commons/AuthTokenUtils";
import { getAuthToken } from "../../commons/AuthTokenUtils";
import { Box } from "@mui/material";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import {
  EMPTY_DEFINITION,
  FRONTEND_URL_DEFINITION,
} from "../../commons/LaConstants";
import { BiSolidTrash, BiSolidPencil } from "react-icons/bi";
import CopyToClipboard from "../../components/copyToClipboard/CopyToClipboard.component";
import { useNavigate } from "react-router";
import LaLink from "../../components/link/LaLink.component";

const DefinitionInTermTree = (props: {
  definition: Response_Definition;
  isInline?: boolean;
}) => {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const [loading, setLoading] = React.useState(false);
  const token = getAuthToken();
  let userId = "";
  let role = "";
  if (token) {
    userId = fetchUserIdFromJwt(token);
    role = fetchRoleFromJwt(token);
  }

  let definitionParam: Response_Definition = props.definition
    ? props.definition
    : EMPTY_DEFINITION;

  const [definition, setDefinition] = React.useState(definitionParam);

  const [updatedContent, setUpdatedContent] = React.useState(
    definitionParam.content
  );
  const [isDeleted, setIsDeleted] = React.useState(false);
  const [updateCount, setUpdateCount] = React.useState(0);
  const navigate = useNavigate();

  const deleteHandler = () => {
    if (window.confirm("Are you sure you want to delete this definition?")) {
      setLoading(true);
      let promise =
        role === "admin"
          ? deleteDefinition_Admin(definition.id)
          : deleteDefinition(definition.id);
      promise
        .then((response) => {
          setDefinition(response.data);
          setUpdatedContent("");
          setIsDeleted(true);
        })
        .catch((error) => {
          handleErrorRouting(error, (url: string) => navigate(url));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const editHandler = () => {
    if (
      window.confirm(
        "All votes will be deleted. Are you sure you want to update this definition?"
      )
    ) {
      if (!verifyDefinitionContentAndAlert(updatedContent)) {
        return;
      }

      let preprocessedUpdatedContent =
        preprocessReactQuillContent(updatedContent);
      setLoading(true);
      let promise =
        role === "admin"
          ? updateDefinition_Admin(definition.id, {
              content: preprocessedUpdatedContent,
            })
          : updateDefinition(definition.id, {
              content: preprocessedUpdatedContent,
            });
      promise
        .then((response) => {
          setDefinition(response.data);
          setUpdatedContent(response.data.content);
          setShow(false);
          setUpdateCount(updateCount + 1);
        })
        .catch((error) => {
          handleErrorRouting(error, (url: string) => navigate(url));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading ? (
        <Box>
          <LaSpinner />
        </Box>
      ) : !isDeleted ? (
        <>
          <span
            className={`${classes.definition} ${
              props.isInline
                ? ` ${classes.inline_def}`
                : ` ${classes.topBottomMargins}`
            }`}
          >
            <span className={classes.definitionContent}>
              {/* <p>{definition.content}</p> */}
              <div className="d-flex justify-content-start mb-4 gap-2">
                <LaLink to={"/la/" + definition.authorId}>
                  <div className={classes.avatar}>
                    {getInitials(definition.authorDisplayName)}
                  </div>
                </LaLink>
                <div>
                  <div className={classes.definitionAuthor}>
                    <LaLink
                      to={"/la/" + definition.authorId}
                      laStyle="link-title"
                    >
                      {definition.authorDisplayName}
                    </LaLink>
                  </div>
                  {role === "admin" && (
                    <div className={classes.definitionAuthorAdmin}>
                      {"Author id: " + definition.authorId}
                    </div>
                  )}
                  <ul className={classes.definitionMeta}>
                    <li>Added: {epochToDateTime(definition.createdOn)}</li>
                    <li>
                      Updated: {epochToDateTime(definition.lastUpdatedOn)}
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={classes.overflowWrap}
                dangerouslySetInnerHTML={{ __html: definition.content }}
              />
              <div className="d-flex justify-content-between align-items-center pt-2">
                <div style={{ minWidth: "150px" }}>
                  <VotingGrid
                    votingScore={definition.votingScore}
                    id={definition.id}
                    selfVote={definition.authorId === userId}
                    authorUpvoted={definition.upvoted}
                    authorDownvoted={definition.downvoted}
                    updateCount={updateCount}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <span className={classes.editDeleteButtons}>
                    {/* IoCopy */}
                    <CopyToClipboard
                      textToCopy={`${FRONTEND_URL_DEFINITION}${definition.id}`}
                    />
                    {definition.authorId === userId || role === "admin" ? (
                      <>
                        <LaButton
                          laStyle="btn-icon"
                          onClick={() => setShow(true)}
                        >
                          <BiSolidPencil size={20} />
                        </LaButton>
                        <LaButton
                          laStyle="btn-icon"
                          onClick={() => deleteHandler()}
                        >
                          <BiSolidTrash size={20} />
                        </LaButton>
                      </>
                    ) : null}
                  </span>
                </div>
              </div>
            </span>
          </span>

          <LaModal
            show={show}
            onHide={handleClose}
            body={
              <div className={classes.newterm}>
                <div className="py-4 my-1">
                  <Form.Group>
                    {/* <InlineErrorMessage errorMessage={errorMessage} /> */}
                    {/* <Form.Label>Term</Form.Label> */}
                    <Form.Control
                      type="text"
                      disabled
                      className={`${classes.newtermTop}  border shadow-none`}
                      placeholder="Add term name.."
                      value="Edit a definition"
                      readOnly
                    />
                    {/* <Form.Label>Definition</Form.Label> */}
                    <Form.Text></Form.Text>
                    <ReactQuill
                      value={updatedContent}
                      style={{ height: "300px" }}
                      placeholder="Add definition.."
                      onChange={(value) => {
                        setUpdatedContent(value);
                      }}
                      className={`quill-inline ${classes.quillContainer}`}
                    />
                  </Form.Group>
                </div>
                <div>
                  <LaButton
                    onClick={() => editHandler()}
                    fullWidth={true}
                    laStyle={"btn-navy"}
                    customClassName="mb-3 mt-2"
                    children={
                      loading ? (
                        <Box sx={{ width: "100%", textAlign: "center" }}>
                          <LaSpinner variant="light" sm />
                        </Box>
                      ) : (
                        "Submit"
                      )
                    }
                  />
                </div>
              </div>
            }
          />
        </>
      ) : (
        <span className={classes.topMargin + " " + classes.bottomMargin}>
          <Alert>The definition has been deleted successfully!</Alert>
        </span>
      )}
    </>
  );
};

export default DefinitionInTermTree;
