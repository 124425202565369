import React, { useEffect, useState } from "react";
import classes from "./Notifications.module.css";
import { getUserUnreadNotifications } from "../../../commons/Api";
import Response_Notification from "../../../types/responses/Response.Notification.type";
import LaButton from "../../../components/button/LaButton.component";
import { markUserNotificationsAsSeen } from "../../../commons/Api";
import {
  COLOR_BBLUE,
  COLOR_BDARKGRAY,
  COLOR_BMEDIUMGRAY,
} from "../../../commons/LaColors";
import { RiCheckDoubleFill } from "react-icons/ri";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  Tooltip,
} from "@mui/material";
import { FaBell } from "react-icons/fa";
import LaSpinner from "../../../components/spinner/LaSpinner.component";
import { useNavigate } from "react-router";
import { epochToDateTime, handleErrorRouting } from "../../../commons/LaUtils";
import { MenuButton } from "../../Layout";

const Notifications = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [listOfNotifications, setListOfNotifications] =
    useState<Response_Notification[]>();
  const [notificationsListHeight, setNotificationsListHeight] =
    useState<String>("70px");

  const navigate = useNavigate();

  const refreshNotificationsListHeight = (list: Response_Notification[]) => {
    if (!list || list.length === 0) {
      setNotificationsListHeight("70px");
    } else {
      if (list?.length === 1) {
        setNotificationsListHeight("170px");
      } else {
        setNotificationsListHeight("340px");
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getUserUnreadNotifications()
      .then((response) => {
        setListOfNotifications(response.data);
        refreshNotificationsListHeight(response.data);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearNotifications = () => {
    var list: string[] = [];
    listOfNotifications?.map((n) => list.push(n.id));
    markUserNotificationsAsSeen({
      ids: list,
    })
      .then((response) => {
        setListOfNotifications([]);
        refreshNotificationsListHeight([]);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      });
  };

  let listWithDividers: JSX.Element[] = [];
  listOfNotifications?.forEach((item, index) => {
    listWithDividers.push(
      <ListItem key={index}>
        <ListItemText
          primary={item.message}
          secondary={epochToDateTime(item?.createdOn)}
        />
      </ListItem>
    );
    if (listOfNotifications[index + 1] !== undefined) {
      listWithDividers.push(<Divider key={"divider-" + index} />);
    }
  });

  return loading ? (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <LaSpinner />
    </Box>
  ) : (
    <div>
      {props.mobileVersion ? (
        <div
          style={{
            position: "relative",
          }}
        >
          {listOfNotifications?.length !== 0 && (
            <div className={classes.number}>{listOfNotifications?.length}</div>
          )}
          <FaBell
            size={24}
            color={
              props.showNotificationsMobileButton
                ? COLOR_BBLUE
                : COLOR_BMEDIUMGRAY
            }
            onClick={() => {
              props.handleMenuButtonClick(MenuButton.notifications);
              navigate("/user/notifications");
            }}
            className="my-auto"
          />
        </div>
      ) : (
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Notifications">
            <IconButton
              onClick={handleClick}
              size="small"
              //sx={{ ml: 0, padding: { xs: 0 } }}
              sx={{ ml: "24px" }}
              aria-controls={open ? "notifications" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              {listOfNotifications?.length !== 0 && (
                <div className={classes.number}>
                  {listOfNotifications?.length}
                </div>
              )}
              <FaBell size={24} color={COLOR_BBLUE} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <div className={`${classes.box}`}>
        <Menu
          anchorEl={anchorEl}
          id="notification-list"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              width: { xs: 400, md: 400 },
              height: {
                md: `${notificationsListHeight}`,
              },
              rightL: 0,
              overflow: "visible",
              boxShadow: "  2px 4px 10px rgba(0,0,0,0.12)",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 2,
              ml: "55px",
              "& .MuiAvatar-root": {
                width: 40,
                height: 40,
                ml: -0.3,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: { xs: 63, md: "63px" },
                width: 20,
                height: 20,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
              color: COLOR_BDARKGRAY,
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div className={classes.list}>
            <List dense={true}>
              {listOfNotifications?.length === 0 ? (
                <ListItem key={-1}>
                  <ListItemText
                    primary="No notifications"
                    //secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
              ) : (
                <>
                  <ListItem key={"button"}>
                    <div className="container d-sm-flex justify-content-end">
                      <LaButton
                        laStyle="btn-underline"
                        onClick={() => clearNotifications()}
                        children={
                          <div>
                            <span>Clear notifications</span>{" "}
                            <RiCheckDoubleFill size={16} fontWeight={700} />
                          </div>
                        }
                      />
                    </div>
                  </ListItem>
                  <Divider key="divider" />
                  {listWithDividers?.map((n) => n)}
                </>
              )}
            </List>
          </div>
          {listOfNotifications && listOfNotifications?.length >= 1 && (
            <div className="mx-3 my-3">
              <LaButton
                type="button"
                fullWidth
                onClick={() => {
                  navigate("user/notifications");
                }}
                laStyle="btn-navy"
              >
                Display all
              </LaButton>
            </div>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default Notifications;
