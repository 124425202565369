import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import classes from "./UserProfile.module.css";
import LaSpinner from "../../../components/spinner/LaSpinner.component";
import PageTitle from "../../../components/pageTitle/PageTitle.component";
import {
  deleteUserAccount_Admin,
  getUserProfile,
  getUserProfile_Admin,
  updateUserProfile,
  updateUserProfile_Admin,
} from "../../../commons/Api";
import LaButton from "../../../components/button/LaButton.component";
import StyledTextField from "../../../components/mui/StyledTextField";
import { Alert } from "react-bootstrap";
import {
  handleErrorRouting,
  isValidAbout,
  isValidDisplayName,
  isValidFirstOrLastName,
} from "../../../commons/LaUtils";
import { Typography } from "@mui/material";
import {
  fetchRoleFromJwt,
  getAuthToken,
  logout,
} from "../../../commons/AuthTokenUtils";
import InlineErrorMessage from "../../../components/alerts/InlineErrorMessage.component";
import { BiSolidUser } from "react-icons/bi";
import { COLOR_BMEDIUMGRAY } from "../../../commons/LaColors";
import { useNavigate } from "react-router";
import { BsCaretLeftFill } from "react-icons/bs";

const UserProfile = (props: { id?: string }) => {
  const [loading, setLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isDeletedByAdmin, setIsDeletedByAdmin] = useState(false);
  const [id, setId] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [currentDisplayName, setCurrentDisplayName] = useState<string>("");
  const [updatedDisplayName, setUpdatedDisplayName] = useState<string>("");
  const [currentFirstName, setCurrentFirstName] = useState<string>("");
  const [updatedFirstName, setUpdatedFirstName] = useState<string>("");
  const [currentLastName, setCurrentLastName] = useState<string>("");
  const [updatedLastName, setUpdatedLastName] = useState<string>("");
  const [currentAbout, setCurrentAbout] = useState<string>("");
  const [updatedAbout, setUpdatedAbout] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const navigate = useNavigate();

  let INVALID_DISPLAYNAME_MESSAGE =
    "Display name should have 2 to 70 characters";

  let INVALID_FIRSTNAME_MESSAGE = "First name may have at most 35 characters";

  let INVALID_LASTNAME_MESSAGE = "Last name may have at most 35 characters";

  let INVALID_ABOUT_MESSAGE = "'About' may have at most 1500 characters";

  const token = getAuthToken();
  let role = "";

  if (props.id && token) {
    role = fetchRoleFromJwt(token);
  }

  useEffect(() => {
    if (props.id && role !== "admin") {
      logout(() => navigate("/"));
      return;
    }

    let promise =
      role === "admin" && props.id
        ? getUserProfile_Admin(props.id)
        : getUserProfile();

    promise
      .then((response) => {
        setId(response.data.id);
        setEmail(response.data.email);
        setCurrentDisplayName(
          response.data.displayName ? response.data.displayName : ""
        );
        setCurrentFirstName(
          response.data.firstName ? response.data.firstName : ""
        );
        setCurrentLastName(
          response.data.lastName ? response.data.lastName : ""
        );
        setCurrentAbout(response.data.about ? response.data.about : "");
        setUpdatedDisplayName(
          response.data.displayName ? response.data.displayName : ""
        );
        setUpdatedFirstName(
          response.data.firstName ? response.data.firstName : ""
        );
        setUpdatedLastName(
          response.data.lastName ? response.data.lastName : ""
        );
        setUpdatedAbout(response.data.about ? response.data.about : "");
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleUpdate = () => {
    setErrorMessage([]);
    if (props.id && role !== "admin") {
      logout(() => navigate("/"));
    }
    if (
      updatedDisplayName === currentDisplayName &&
      updatedFirstName === currentFirstName &&
      updatedLastName === currentLastName &&
      updatedAbout === currentAbout
    ) {
      setErrorMessage(["No element was edited!"]);
      return;
    }

    let updatedErrorMessage: string[] = [];

    if (!isValidDisplayName(updatedDisplayName)) {
      updatedErrorMessage.push(INVALID_DISPLAYNAME_MESSAGE);
    }
    if (!isValidFirstOrLastName(updatedFirstName)) {
      updatedErrorMessage.push(INVALID_FIRSTNAME_MESSAGE);
    }
    if (!isValidFirstOrLastName(updatedLastName)) {
      updatedErrorMessage.push(INVALID_LASTNAME_MESSAGE);
    }
    if (!isValidAbout(updatedAbout)) {
      updatedErrorMessage.push(INVALID_ABOUT_MESSAGE);
    }

    if (updatedErrorMessage.length !== 0) {
      setErrorMessage(updatedErrorMessage);
      return;
    }

    setLoading(true);
    let promise =
      role === "admin" && props.id
        ? updateUserProfile_Admin(props.id, {
            displayName: updatedDisplayName,
            firstName: updatedFirstName,
            lastName: updatedLastName,
            about: updatedAbout,
          })
        : updateUserProfile({
            displayName: updatedDisplayName,
            firstName: updatedFirstName,
            lastName: updatedLastName,
            about: updatedAbout,
          });

    promise
      .then((response) => {
        setCurrentDisplayName(response.data.displayName);
        setCurrentFirstName(response.data.firstName);
        setCurrentLastName(response.data.lastName);
        setCurrentAbout(response.data.about);
        setUpdatedDisplayName(response.data.displayName);
        setUpdatedFirstName(response.data.firstName);
        setUpdatedLastName(response.data.lastName);
        setUpdatedAbout(response.data.about);
        setIsChanged(true);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAdminDelete = () => {
    if (!props.id || role !== "admin") {
      logout(() => navigate("/"));
      return;
    }

    setLoading(true);
    deleteUserAccount_Admin(props.id)
      .then((response) => {
        setIsDeletedByAdmin(true);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return loading ? (
    <LaSpinner />
  ) : (
    <div className={classes.profilePage}>
      <>
        <PageTitle
          title="Profile"
          insidePage
          backButton={
            <BsCaretLeftFill
              onClick={() => navigate("/user/preferences")}
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          }
        />
        {isChanged ? (
          <div>
            <Alert>User profile is updated successfully!</Alert>
          </div>
        ) : isDeletedByAdmin ? (
          <div>
            <Alert>User profile is deleted successfully!</Alert>
          </div>
        ) : (
          <Box sx={{ mt: 1, width: { xs: "100%", md: "50%" } }}>
            <InlineErrorMessage errorMessage={errorMessage} />
            <Typography
              align="left"
              component="h1"
              variant="body1"
              sx={{ mb: 2 }}
            >
              <span className={classes.userSpan}>User id : </span> <br /> {id}
            </Typography>
            <Typography
              align="left"
              component="h1"
              variant="body1"
              sx={{ mb: 2 }}
            >
              <span className={classes.userSpan}>Email : </span> <br /> {email}
            </Typography>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="displayName"
              label={
                <>
                  <BiSolidUser
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  Display name
                </>
              }
              name="displayName"
              value={updatedDisplayName}
              onChange={(e) => setUpdatedDisplayName(e.target.value)}
              size="small"
            />
            <StyledTextField
              margin="normal"
              fullWidth
              id="firstName"
              label={
                <>
                  <BiSolidUser
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  First name
                </>
              }
              name="firstName"
              value={updatedFirstName}
              onChange={(e) => setUpdatedFirstName(e.target.value)}
              size="small"
            />
            <StyledTextField
              margin="normal"
              fullWidth
              id="lastName"
              label={
                <>
                  <BiSolidUser
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  Last name
                </>
              }
              name="lastName"
              value={updatedLastName}
              onChange={(e) => setUpdatedLastName(e.target.value)}
              size="small"
              sx={{ mb: 0 }}
            />
            <Typography
              align="left"
              component="h1"
              variant="body1"
              sx={{ mb: 1 }}
            >
              <span className={classes.userSpan}>
                <br />
                About
              </span>
            </Typography>
            <textarea
              value={updatedAbout}
              className={classes.textareaAbout}
              onChange={(e) => setUpdatedAbout(e.target.value)}
            ></textarea>
            {/*
            <StyledTextarea
              disabled={false}
              minRows={2}
              placeholder="About"
              id="about"
              name="about"
              value={updatedAbout}
              onChange={(e) => setUpdatedAbout(e.target.value)}
            />*/}
            <div className={classes.buttonTopMargin}>
              <LaButton onClick={() => handleUpdate()} laStyle="btn-navy">
                Update profile
              </LaButton>
            </div>
            {role === "admin" && (
              <div className={classes.buttonTopMargin}>
                <LaButton onClick={() => handleAdminDelete()} laStyle="btn-red">
                  Delete profile
                </LaButton>
              </div>
            )}
          </Box>
        )}
      </>
    </div>
  );
};

export default UserProfile;
