import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Response_DefinitionsPage from "../../types/responses/Response.DefinitionsPage.type";
import Response_Definition from "../../types/responses/Response.Definition.type";
import PreviousNext from "../../components/previousNext/PreviousNext.component";
import {
  getAuthorDefinitionsPublic,
  getAuthorDefinitions_Admin,
  getUserDefinitions,
} from "../../commons/Api";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import { AxiosResponse } from "axios";
import PageTitle from "../../components/pageTitle/PageTitle.component";
import DefinitionCard from "../definitionCard/DefinitionCard";
import TotalElementCount from "../../components/totalElementCount/TotalElementCount.component";
import Element_Type from "../../types/Element.type";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { COLOR_BBLUE, COLOR_BMEDIUMGRAY } from "../../commons/LaColors";
import { BsCaretLeftFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import { FaSortAmountDown } from "react-icons/fa";
import { handleErrorRouting } from "../../commons/LaUtils";

export enum DefinitionCardList_Type {
  myDefinitions,
  authorDefinitionsPublic,
  authorDefinitionsAdmin,
}

const DefinitionCardList = (props: {
  type: DefinitionCardList_Type;
  id?: string;
  ignoreBackButton?: boolean;
}) => {
  const [data, setData] = useState<Response_DefinitionsPage>();
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState<string | undefined>(undefined);
  const [selectedOrderedBy, setSelectedOrderedBy] =
    useState<string>("votingScore");
  const navigate = useNavigate();

  let title = "No title";

  if (props.type === DefinitionCardList_Type.myDefinitions) {
    title = "My definitions";
  }
  if (props.type === DefinitionCardList_Type.authorDefinitionsPublic) {
    title = "Definitions";
  }
  if (props.type === DefinitionCardList_Type.authorDefinitionsAdmin) {
    title = "Author definitions";
  }

  useEffect(() => {
    getData(selectedOrderedBy, undefined);
  }, []);

  const getData = (orderedBy: string | undefined, page: string | undefined) => {
    setData(undefined);

    let promise: Promise<AxiosResponse<Response_DefinitionsPage, any>>;

    if (props.type === DefinitionCardList_Type.myDefinitions) {
      promise = getUserDefinitions(orderedBy, page, undefined);
    } else if (props.type === DefinitionCardList_Type.authorDefinitionsPublic) {
      if (!props.id) {
        setLoading(false);
        return Promise.reject("id undefined");
      }
      promise = getAuthorDefinitionsPublic(
        props.id,
        orderedBy,
        page,
        undefined
      );
    } else {
      if (!props.id) {
        setLoading(false);
        return Promise.reject("id undefined");
      }
      promise = getAuthorDefinitions_Admin(
        props.id,
        orderedBy,
        page,
        undefined
      );
    }

    promise
      .then((response) => {
        console.error(response.data);
        setData(response.data);
        setNextPage(
          response.data.nextPage === null ? undefined : response.data.nextPage
        );
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Box>
          <LaSpinner />
        </Box>
      ) : (
        <div style={{ paddingTop: "20px" }}>
          <div>
            <div
              className="d-flex  justify-content-end d-md-none"
              style={{
                zIndex: "1",
                position: "relative",
              }}
            >
              {!props.ignoreBackButton && (
                <BsCaretLeftFill
                  onClick={() => navigate("/user/preferences")}
                  className="ms-auto  d-md-none"
                  size={24}
                  color={COLOR_BMEDIUMGRAY}
                />
              )}
            </div>
            <PageTitle
              title={title}
              subTitle={
                <TotalElementCount
                  countedElement={Element_Type.definition}
                  totalElementCount={
                    data === undefined ? 0 : data.totalDefinitionCount
                  }
                />
              }
              sortBy={
                <div>
                  <div className="d-flex justify-content-end align-items-center">
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      className="d-none d-md-block"
                      sx={{ color: COLOR_BMEDIUMGRAY, mr: 1 }}
                    >
                      Sorted by:
                    </InputLabel>
                    <FaSortAmountDown
                      color={COLOR_BMEDIUMGRAY}
                      size={24}
                      className="me-2 d-md-none"
                    />
                    <FormControl
                      className="form-control-border"
                      sx={{
                        ":hover": { color: COLOR_BMEDIUMGRAY },
                        border: "none",
                      }}
                    >
                      <Select
                        inputProps={{
                          name: "orderdBy",
                          id: "uncontrolled-native",
                          className: "py-2",
                        }}
                        onChange={(e) => {
                          setSelectedOrderedBy(e.target.value);
                          getData(e.target.value, undefined);
                        }}
                        value={selectedOrderedBy}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: COLOR_BBLUE,
                          },
                          textDecoration: "none",
                          width: "200px",
                        }}
                      >
                        <MenuItem
                          value={"votingScore"}
                          children="Highest voting score"
                        />
                        <MenuItem
                          value={"createdOn"}
                          children="Date created (Newest first)"
                        />
                      </Select>
                    </FormControl>
                  </div>
                </div>
              }
              insidePage={true}
            />
          </div>
          {data !== undefined &&
            data.definitions.map((definition: Response_Definition, index) => {
              return (
                <DefinitionCard isProfile definition={definition} key={index} />
              );
            })}

          <PreviousNext
            orderedBy={selectedOrderedBy}
            nextPage={nextPage}
            getData={getData}
          />
        </div>
      )}
    </>
  );
};

export default DefinitionCardList;
