import { BiSolidUser } from "react-icons/bi";
import VerticalMenu from "../../layout/verticalMenu/VerticalMenu.component";
import VerticalMenuSectionItems from "../../types/VerticalMenuSectionItems.type";
import { BsCaretRightFill } from "react-icons/bs";
import { COLOR_BMEDIUMGRAY } from "../../commons/LaColors";

const AdminSettings = () => {
  let section1: VerticalMenuSectionItems = {
    items: [
      {
        to: "/admin/settings/termcard",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} /> <p className="my-auto ms-2">Term</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/admin/settings/term",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} /> <p className="my-auto ms-2">Term tree</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/admin/settings/definition",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} /> <p className="my-auto ms-2">Definition</p>
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
    ],
  };

  let section2: VerticalMenuSectionItems = {
    items: [
      {
        to: "/admin/settings/authorprofile",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} />{" "}
            <p className="my-auto ms-2">Author profile</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/admin/settings/authordefinitions",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} />{" "}
            <p className="my-auto ms-2">Author definitions</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/admin/settings/pending/",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} />{" "}
            <p className="my-auto ms-2">Pending terms</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
    ],
  };

  let section3: VerticalMenuSectionItems = {
    items: [
      {
        to: "/admin/settings/alltables",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} /> <p className="my-auto ms-2">All tables</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/admin/settings/allusers",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} /> <p className="my-auto ms-2">All users</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
    ],
  };

  let section4: VerticalMenuSectionItems = {
    items: [
      {
        to: "/admin/settings/adminnotifications",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} />{" "}
            <p className="my-auto ms-2">Admin notifications</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/admin/settings/authornotifications",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} />{" "}
            <p className="my-auto ms-2">Author notifications</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
    ],
  };

  return (
    <div className="max-width">
      <VerticalMenu
        sections={[section1, section2, section3, section4]}
        title="Admin Settings"
      />
    </div>
  );
};
export default AdminSettings;
