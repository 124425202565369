import { useEffect, useState } from "react";
import DefinitionInTermTree from "../../definitions/definitionInTermTree/DefinitionInTermTree";
import InlineAddDefinition from "../../definitions/inlineAddDefinition/InlineAddDefinition";
import { useParams } from "react-router-dom";
import { Response_TermTree } from "../../types/responses/Response.TermTree.type";
import Box from "@mui/material/Box";
import PreviousNext from "../../components/previousNext/PreviousNext.component";
import { getTermTree, getTermTree_Admin } from "../../commons/Api";
import PageTitle from "../../components/pageTitle/PageTitle.component";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import { useNavigate } from "react-router-dom";
import {
  handleErrorRouting,
  stateToString,
  toPrettyTermName,
} from "../../commons/LaUtils";
import TotalElementCount from "../../components/totalElementCount/TotalElementCount.component";
import { EMPTY_TERM_TREE } from "../../commons/LaConstants";
import ApproveRejectTermElement from "../approveRejectTermElement/ApproveRejectTermElement";
import { Alert } from "react-bootstrap";
import Element_Type from "../../types/Element.type";
import { getAuthToken, fetchRoleFromJwt } from "../../commons/AuthTokenUtils";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { COLOR_BBLUE, COLOR_BMEDIUMGRAY } from "../../commons/LaColors";
import { FaSortAmountDown } from "react-icons/fa";

const TermTree = (props: {
  propTermId?: string;
  blockUrlCorrection?: boolean;
}) => {
  const { id, name } = useParams();
  const [termTree, setTermTree] = useState<Response_TermTree>(EMPTY_TERM_TREE);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState<string | undefined>(undefined);
  const [approvedSuccessfully, setApprovedSuccessfully] =
    useState<boolean>(false);
  const [rejectedSuccessfully, setRejectedSuccessfully] =
    useState<boolean>(false);
  const [selectedOrderedBy, setSelectedOrderedBy] =
    useState<string>("votingScore");

  const navigate = useNavigate();

  let token = getAuthToken();
  let role = "";
  if (token) {
    role = fetchRoleFromJwt(token);
  }

  useEffect(() => {
    getData(selectedOrderedBy, undefined);
  }, [id]);

  const getData = (orderedBy: string | undefined, page: string | undefined) => {
    //setLoading(true); TODO-adi (adding it refreshes the PreviousNext buttons)
    setTermTree(EMPTY_TERM_TREE);
    var adoptedTermId = id;
    if (adoptedTermId === undefined) {
      adoptedTermId = props.propTermId;
      if (adoptedTermId === undefined) {
        console.error("undefined id");
        return;
      }
    }

    let promise =
      role === "admin"
        ? getTermTree_Admin(adoptedTermId, orderedBy, page, undefined)
        : getTermTree(adoptedTermId, orderedBy, page, undefined);

    promise
      .then((response) => {
        // case url includes invalid or empty term name
        if (
          !props.blockUrlCorrection &&
          (name === undefined ||
            name !== toPrettyTermName(response.data.term.name))
        ) {
          navigate(
            "/term/" +
              adoptedTermId +
              "/" +
              toPrettyTermName(response.data.term.name)
          );
        }
        setTermTree(response.data);
        setNextPage(
          response.data.nextPage === null ? undefined : response.data.nextPage
        );
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <LaSpinner />
        </Box>
      ) : rejectedSuccessfully ? (
        <Alert>
          The term '{termTree.term.id}' has been rejected successfully.
        </Alert>
      ) : approvedSuccessfully ? (
        <Alert>
          The term '{termTree.term.id}' has been approved successfully.
        </Alert>
      ) : (
        <div className="max-width row-sm">
          <div>
            <PageTitle
              title={
                termTree.term.name !== undefined ? termTree.term.name : "<>"
              }
              subTitle={
                <TotalElementCount
                  countedElement={Element_Type.definition}
                  totalElementCount={
                    termTree === undefined ? 0 : termTree.totalDefinitionCount
                  }
                />
              }
              sortBy={
                <div className="ms-auto me-1 mt-2 mt-md-0">
                  <div className="d-flex justify-content-center align-items-center">
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      className="d-none d-md-block"
                      sx={{ color: COLOR_BMEDIUMGRAY, mr: 1 }}
                    >
                      Sorted by:
                    </InputLabel>
                    <FaSortAmountDown
                      color={COLOR_BMEDIUMGRAY}
                      size={24}
                      className="me-2 d-md-none"
                    />

                    <FormControl
                      className="form-control-border"
                      sx={{
                        ":hover": { color: COLOR_BMEDIUMGRAY },
                        border: "none",
                      }}
                    >
                      <Select
                        inputProps={{
                          name: "orderdBy",
                          id: "uncontrolled-native",
                          className: "py-2",
                        }}
                        onChange={(e) => {
                          setSelectedOrderedBy(e.target.value);
                          getData(e.target.value, undefined);
                        }}
                        value={selectedOrderedBy}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: COLOR_BBLUE,
                          },
                          textDecoration: "none",
                          width: "200px",
                        }}
                      >
                        <MenuItem
                          value={"votingScore"}
                          children="Highest voting score"
                        />
                        <MenuItem
                          value={"createdOn"}
                          children="Date created (Newest first)"
                        />
                      </Select>
                    </FormControl>
                  </div>
                </div>
              }
            />
          </div>
          <div>
            {stateToString(termTree.term.state) === "pending" &&
              role === "admin" && (
                <ApproveRejectTermElement
                  termId={termTree.term.id}
                  termName={termTree.term.name}
                  setApprovedSuccessfully={setApprovedSuccessfully}
                  setRejectedSuccessfully={setRejectedSuccessfully}
                />
              )}
          </div>
          {termTree.definitions.map((def, index) => {
            return (
              <div key={index}>
                <DefinitionInTermTree definition={def} />
                {index === 0 && id ? (
                  <InlineAddDefinition
                    //index={index}
                    termId={id}
                    termName={termTree.term.name}
                  />
                ) : null}
              </div>
            );
          })}
          <PreviousNext
            nextPage={nextPage}
            getData={getData}
            orderedBy={selectedOrderedBy}
          />
        </div>
      )}
    </>
  );
};

export default TermTree;
