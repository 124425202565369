/**
 * Copied from https://github.com/mui/material-ui/blob/v5.12.0/docs/data/material/getting-started/templates/sign-in/SignIn.tsx
 */
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  COLOR_BDARKGRAY,
  COLOR_BMEDIUMGRAY,
  COLOR_BNAVY,
  COLOR_BWHITE,
} from "../../commons/LaColors";
import StyledTextField from "../../components/mui/StyledTextField";
import LaButton from "../../components/button/LaButton.component";
import { authenticate } from "../../commons/Api";

import { useNavigate } from "react-router";
import LaLink from "../../components/link/LaLink.component";
import { login } from "../../commons/AuthTokenUtils";
import { analyzeAxiosError, handleErrorRouting } from "../../commons/LaUtils";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import { isValidEmail } from "../../commons/LaUtils";
import { ATTR_REDIRECT } from "../../commons/LaConstants";
import InlineErrorMessage from "../../components/alerts/InlineErrorMessage.component";
import { BiSolidEnvelope, BiSolidLock } from "react-icons/bi";

const theme = createTheme();

const LogIn = () => {
  let WRONG_CREDENTIALS_MESSAGE = "Wrong email or password";

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string[]>([]);
  const navigate = useNavigate();

  let redirect = "";
  React.useEffect(() => {
    let redirectParam = new URLSearchParams(window.location.search).get(
      ATTR_REDIRECT
    );
    redirect = redirectParam ? redirectParam : "";
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    setErrorMessage([]);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get("email");
    let password = data.get("password");
    if (
      !email ||
      typeof email !== "string" ||
      !password ||
      typeof password !== "string" ||
      !isValidEmail(email) ||
      password.length < 6
    ) {
      setErrorMessage([WRONG_CREDENTIALS_MESSAGE]);
      setLoading(false);
      return;
    }
    authenticate({
      email: email,
      password: password,
    })
      .then((response) => {
        const token = response.data.jwt;
        if (token === undefined) {
          setErrorMessage([WRONG_CREDENTIALS_MESSAGE]);
        } else {
          login(response.data.jwt, () => navigate(redirect));
        }
      })
      .catch((error) => {
        let errorCode = analyzeAxiosError(error);
        if (errorCode === -1 || errorCode >= 500) {
          handleErrorRouting(error, (url: string) => navigate(url));
        } else {
          setErrorMessage([WRONG_CREDENTIALS_MESSAGE]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          background: COLOR_BWHITE,
          borderRadius: "6px",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: { xs: "10px", md: 4 },
            paddingBottom: { xs: "10px", md: 4 },
            paddingTop: { xs: "10px", md: 4 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: COLOR_BNAVY }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color={COLOR_BDARKGRAY}>
            Log in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <InlineErrorMessage errorMessage={errorMessage} />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={
                <>
                  <BiSolidEnvelope
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  Email Address
                </>
              }
              name="email"
              autoComplete="email"
              autoFocus
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={
                <>
                  <BiSolidLock
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  Password
                </>
              }
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <LaButton
              type="submit"
              fullWidth
              onClick={() => handleSubmit}
              laStyle="btn-navy"
              customClassName="my-3 py-12"
            >
              {loading ? (
                <Box
                  sx={{
                    width: "40px",
                    margin: "auto",
                    marginBottom: "8px",
                    marginTop: "8px",
                    textAlign: "center",
                  }}
                >
                  <LaSpinner variant="light" sm />
                </Box>
              ) : (
                "Log in"
              )}
            </LaButton>
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <div>
                <LaLink laStyle="link-underline-blue" to="/user/forgotpassword">
                  Forgot password?
                </LaLink>
              </div>
              <div>
                <LaLink laStyle="link-underline-blue" to="/user/signup">
                  {"Don't have an account? Sign Up"}
                </LaLink>
              </div>
            </div>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LogIn;
